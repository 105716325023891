<script
    lang="ts"
    setup
>
    const { public: { landingUrl } } = useRuntimeConfig()
</script>

<template>
    <div class="relative flex flex-col w-full h-full backface-invisible bg-white text-black">
        <header
            class="
                flex
                items-center
                justify-between
                min-h-[68px]
                tablet:min-h-[84px]
                mb-[40px]
                tablet:mb-[46px]
                px-[16px]
                tablet:px-[60px]
            "
        >
            <NuxtLink
                external
                class="appearance-none cursor-pointer"
                :to="landingUrl"
            >
                <AppBranding
                    size="100"
                    color="#000"
                    without-sizes
                    class="transition-opacity hover:opacity-60 active:opacity-40 cursor-pointer"
                />
            </NuxtLink>

            <AuthLanguageSwitcher />
        </header>

        <slot />
    </div>
</template>
